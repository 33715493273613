<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline"
                    ref="searchRef"
                    :model="searchForm">
        <a-form-model-item prop="phone"
                           label="手机">
          <a-input style="width:200px"
                   placeholder="请输入手机号"
                   v-model="searchForm.phone" />
        </a-form-model-item>
        <a-form-model-item>
          <!-- <a-checkbox class="ml-2"
              v-model="isSortbyIntegral">按积分</a-checkbox> -->
          <a-button class="ml-2" 
                    @click="handlerResetForm">重置</a-button>
          <a-button class="ml-2" type="primary" html-type="submit" 
                    :loading="isLoading"
                    @click="handlerSearch">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <div class="flex justify-between">
        <span class="text-lg font-bold p-3">会员列表</span>
      </div>
    </div>

    <base-table :columnsData="columns"
                :tableData="tableData"
                :getListFunc="initData"
                :total="total"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #nickname="{record}">
        <a-tag v-if="record.close_account == 2" color="red">已注销</a-tag>
        {{record.nickname}}
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="handlerDetail(record.member_id)">详情</a-button>
        <!-- <a-button type="link" @click="handlerMemberOrder(record)">订单</a-button> -->
      </template>
    </base-table>

    <a-drawer :title="'会员详情（uid: '+memberId+'）'"
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowDetail"
              @close="isShowDetail = false">
      <vip-detail :memberId="memberId" v-if="isShowDetail" />
    </a-drawer>

  </div>
</template>

<script>
import pageData from "./columns"
import { getVipList } from "@/api/vip.js"
import { getCityList } from "@/api/outlets"

import VipDetail from './detail.vue'
export default {
  components: { VipDetail },
  data () {
    return {
      ...pageData,
      activeKey: 0,
      isShowDetail: false,
      memberId: '',
      cityList: [],
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },
      total: 0,
      searchForm: {
        name: "",
        phone: "",
        date_type: 0,
        page: 1,
        page_count: 20,
        sort: "",
      },
      isSortbyIntegral: false,
      tableData: [],

      isLoading: false,
    }
  },
  mounted () {
    this.initData()
    this.initCityList()
  },
  methods: {

    async initCityList() {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },

    async initData () {
      const params = this.searchForm
      this.isLoading = true
      const { data, code } = await getVipList(params)
      this.isLoading = false
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    // 搜索
    handlerSearch () {
      this.searchForm.page = 1
      this.searchForm.sort = this.isSortbyIntegral?"2":""
      this.initData()
    },
    // 重置
    handlerResetForm () {
      this.searchForm.phone = ""
      this.searchForm.page = 1
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    handlerDetail (member_id) {
      this.memberId = member_id
      this.isShowDetail = true
    },
    handlerMemberOrder(row){
      const newPage = this.$router.resolve({
        path: "/order",
        query: { memberId: row.member_id },
      })
      window.open(newPage.href, "_blank")
    }

  },
}
</script>

<style>
</style>